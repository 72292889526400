import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { device } from "../../styles/devices";
import PropTypes from "prop-types";

import Formulario from "../../modules/contato/Formulario";
import Logo from "../../modules/ui/Logos";
import Icon from "../../modules/ui/Icon";

const FormFixoStyled = styled.div``;

const FormWrapper = styled.div`
  background-color: white;
  border-radius: 5px;
  box-shadow: ${props => props.theme.bs};
  position: fixed;
  top: 5vh;
  right: 10vw;

  transition: all 0.2s;
  transform: ${props => (props.estaComFoco ? "scale(1.1)" : "scale(1)")};

  /* height: 500px; */
  width: 40rem;
  max-width: 95vw;

  z-index: 5;

  @media ${device.laptopL} {
    right: 5vw;
  }
  @media ${device.laptopM} {
    right: 3vw;
  }
  @media ${device.laptop}, (max-height: 570px) {
    position: relative;
    margin: 6rem auto;
    top: initial;
    right: initial;
    z-index: initial;
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.4);
  }
`;

const ChamadaForm = styled.div`
  padding: 2rem;
  text-align: center;
  position: relative;
`;

const TextoForm = styled.h2`
  margin-top: 1rem;
  color: ${props => props.theme.cores.quatro};
  font-size: 1.7rem;
  font-weight: normal;
`;

const EbookGratis = styled.div`
  position: absolute;
  width: 10rem;
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0d304c;
  color: white;
  border-radius: 50%;
  left: -5rem;
  top: 5rem;

  @media ${device.laptop} {
    left: 0;
    top: -3rem;
  }
`;

const AvisoSiteSeguro = styled.div`
  position: absolute;
  bottom: -7%;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    display: inline-block;
    margin-left: 0.8rem;
    font-size: 1.4rem;
  }
  @media ${device.laptop}, (max-height: 570px) {
    span {
      color: black;
    }
    bottom: -10%;
  }
  @media ${device.laptop} {
    color: ${props => props.theme.greyDarker};
  }
`;

const FormularioStyled = styled(Formulario)`
  margin: 1rem auto 0;
`;

const SombraFadeIn = keyframes`
  0% {
    opacity: 0
  }
  10% {
    opacity: 1
  }
  90% {
    opacity: 1
  }
  100% {
    opacity: 0
  }
`;

const Sombra = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  animation: ${SombraFadeIn} 4s linear;
  animation-fill-mode: forwards;
`;

function FormFixo({ estaComFoco }) {
  const [contatoDisparado, setContatoDisparado] = useState(false);

  return (
    <FormFixoStyled>
      <FormWrapper estaComFoco={estaComFoco} id="form-wrapper">
        <ChamadaForm>
          <Logo colorido tamanho="10rem" />
          {!contatoDisparado && (
            <TextoForm>
              Para fazer o download, basta preencher o formulário abaixo!
            </TextoForm>
          )}
          <EbookGratis>
            <span>
              eBook <br />
              <strong>Grátis</strong>
            </span>
          </EbookGratis>
        </ChamadaForm>
        <FormularioStyled setContatoDisparado={setContatoDisparado} />
        <AvisoSiteSeguro>
          <Icon cor="#61bb31" icon="lock" tamanho="1.8rem" />
          <span>Site 100% seguro e livre de spam</span>
        </AvisoSiteSeguro>
      </FormWrapper>
      {estaComFoco && <Sombra />}
    </FormFixoStyled>
  );
}

FormFixo.propTypes = {
  estaComFoco: PropTypes.bool,
};

FormFixo.defaultProps = {
  estaComFoco: false,
};

export default FormFixo;
