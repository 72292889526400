import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const IconSVG = styled.svg`
  width: ${props => props.tamanho};
  height: ${props => props.tamanho};
  fill: ${props => props.cor};
`;

const Icon = props => (
  <IconSVG {...props}>
    <use xlinkHref={`/icons/symbol-defs.svg#icon-${props.icon}`} />
  </IconSVG>
);

Icon.propTypes = {
  tamanho: PropTypes.string,
  cor: PropTypes.string,
  icon: PropTypes.string.isRequired
};

Icon.defaultProps = {
  tamanho: "6rem",
  cor: "#fff"
};

export default Icon;
