import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styled from "styled-components";

const LinkStyled = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogoSVG = styled.svg`
  width: ${props => props.tamanho};
  height: ${props => props.tamanho};
  fill: ${props => props.cor};
`;

const Logo = props => (
  <LinkStyled to={props.para} title={props.paraTitulo}>
    <LogoSVG {...props}>
      <use
        xlinkHref={`/logos/symbol-defs.svg#icon-${
          props.colorido ? "colorido" : "cinza"
        }`}
      />
    </LogoSVG>
  </LinkStyled>
);

Logo.propTypes = {
  para: PropTypes.string,
  paraTitulo: PropTypes.string,
  colorido: PropTypes.bool,
  tamanho: PropTypes.string,
  cor: PropTypes.string
};

Logo.defaultProps = {
  para: "/",
  paraTitulo: "Ir para a página inicial do Voucher Fácil",
  colorido: false,
  tamanho: "6rem",
  cor: "#fff"
};

export default Logo;
