import React, { useState } from "react";
import styled from "styled-components";
import { pegaViewportWidth, scrollToElement } from "../../helpers/misc";

import Topo from "./Topo";
import Sobre from "./Sobre";
import FormFixo from "./FormFixo";

const HomeStyled = styled.div``;

function Home({
  match: {
    params: { conteudo }
  }
}) {
  //controle de foco do FormFixo
  const [estaComFoco, setEstaComFoco] = useState(false);
  const darFocoFormFixo = () => {
    if (pegaViewportWidth() > 1024) {
      //nesse caso o form esta fixed, entao vamos fazer o efeitinho
      setEstaComFoco(true);
      setTimeout(() => setEstaComFoco(false), 4000);
    } else {
      //faz somente o scroll para o formulario que nao esta mais fixed
      scrollToElement("#form-wrapper");
    }
  };

  return (
    <HomeStyled>
      <Topo darFocoFormFixo={darFocoFormFixo} />
      <Sobre darFocoFormFixo={darFocoFormFixo} />
      <FormFixo estaComFoco={estaComFoco} />
    </HomeStyled>
  );
}

export default Home;
