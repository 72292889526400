import React, { Fragment } from "react";

import styled, { ThemeProvider } from "styled-components";
import { GlobalStyled } from "./styles/global";
import { tema } from "./styles/tema";

import { Provider } from "react-redux";
import reduxStore from "./store";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Home from "./pages/home/Home";
import Footer from "./layout/Footer";

const ContentWrapper = styled.div`
  min-height: 100vh;
`;

const Main = styled.main``;

const App = () => (
  <ThemeProvider theme={tema}>
    <Provider store={reduxStore}>
      <Fragment>
        <GlobalStyled />
        <Router>
          <ContentWrapper>
            <Main>
              <Switch>
                <Route path="/:conteudo?" component={Home} />
              </Switch>
            </Main>
            <Footer />
          </ContentWrapper>
        </Router>
      </Fragment>
    </Provider>
  </ThemeProvider>
);

export default App;
