import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components";
import { device } from "../../styles/devices";

import Icon from "../../modules/ui/Icon";
import { Container } from "../../modules/ui/Containers";
import { ButtonPrimary } from "../../modules/ui/Buttons";

const TopoStyled = styled.section`
  background-color: #dc3b36;
  padding: 4rem 0;
  color: white;
  position: relative;

  padding-bottom: 24rem;

  &::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 35%;
    top: 0;
    right: 0;
    background-color: #c33330;

    @media ${device.laptop} {
      display: none;
    }
  }

  @media ${device.laptop} {
    text-align: center;
  }
`;

const Titulo = styled.h1`
  text-transform: uppercase;
  max-width: 40rem;
  margin-top: 2rem;
  font-weight: 300;
  font-size: 2.8rem;
  line-height: 1.4;

  @media ${device.laptop} {
    margin: 2rem auto 0;
  }
`;

const Subtitulo = styled.h2`
  font-size: 1.8rem;
  color: white;
  display: flex;
  align-items: center;
  span {
    display: inline-block;
    font-weight: normal;
    margin-left: 1rem;
  }

  @media ${device.laptop} {
    justify-content: center;
  }

  @media ${device.mobileL} {
    justify-content: center;
    span {
      font-weight: bold;
    }
  }
`;

const ButtonCall = styled(ButtonPrimary)`
  text-transform: uppercase;
  margin-top: 2rem;
`;

const Topo = ({ darFocoFormFixo }) => (
  <TopoStyled>
    <Container>
      <Subtitulo>
        <Icon icon="document-file-pdf" tamanho="3rem" />
        <span>eBook Grátis</span>
      </Subtitulo>
      <Titulo>
        É preciso criar motivações para que seu restaurante seja um lugar
        imperdível!
      </Titulo>
      {!!darFocoFormFixo && (
        <ButtonCall onClick={darFocoFormFixo}>Download Grátis</ButtonCall>
      )}
    </Container>
  </TopoStyled>
);

Topo.propTypes = {
  darFocoFormFixo: PropTypes.func,
};

Topo.defaultProps = {
  darFocoFormFixo: null,
};

export default Topo;
