import axios from "axios";

/**
 * retorna todos os estados
 */
export const pegaEstadosUf = () => [
  { id: "AC", nome: "Acre", regiao: 1 },
  { id: "AL", nome: "Alagoas", regiao: 2 },
  { id: "AM", nome: "Amazonas", regiao: 1 },
  { id: "AP", nome: "Amapá", regiao: 1 },
  { id: "BA", nome: "Bahia", regiao: 2 },
  { id: "CE", nome: "Ceará", regiao: 2 },
  { id: "DF", nome: "Distrito Federal", regiao: 5 },
  { id: "ES", nome: "Espírito Santo", regiao: 3 },
  { id: "GO", nome: "Goiás", regiao: 5 },
  { id: "MA", nome: "Maranhão", regiao: 2 },
  { id: "MG", nome: "Minas Gerais", regiao: 3 },
  { id: "MS", nome: "Mato Grosso do Sul", regiao: 5 },
  { id: "MT", nome: "Mato Grosso", regiao: 5 },
  { id: "PA", nome: "Pará", regiao: 2 },
  { id: "PB", nome: "Paraíba", regiao: 2 },
  { id: "PE", nome: "Pernambuco", regiao: 2 },
  { id: "PI", nome: "Piauí", regiao: 2 },
  { id: "PR", nome: "Paraná", regiao: 4 },
  { id: "RJ", nome: "Rio de Janeiro", regiao: 3 },
  { id: "RN", nome: "Rio Grande do Norte", regiao: 2 },
  { id: "RO", nome: "Rondônia", regiao: 1 },
  { id: "RR", nome: "Roraima", regiao: 1 },
  { id: "RS", nome: "Rio Grande do Sul", regiao: 4 },
  { id: "SC", nome: "Santa Catarina", regiao: 4 },
  { id: "SE", nome: "Sergipe", regiao: 2 },
  { id: "SP", nome: "São Paulo", regiao: 3 },
  { id: "TO", nome: "Tocantins", regiao: 1 },
];

/**
 * busca todas as cidades de um estado
 * @param {string} uf estado
 */
export const buscaCidadesPorEstado = async uf => {
  try {
    const {
      data: { success, data },
    } = await axios.get(
      `https://voucherfacil.com.br/api/locais/${uf}/cidades/todas`,
    );

    if (success) {
      return data.cidades || null;
    }
    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
};
