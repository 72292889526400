// import { Component } from "react";
// import PropTypes from "prop-types";
// import ReactGA from "react-ga";

//inicializando a property com o id
// ReactGA.initialize("UA-");

/**
 * manda um pageview para o analytics passando o path full ou relativo
 */
export const sendPageView = ({
  pathname,
  relativePath,
  modificarNaBarraTitulo = false,
}) => {
  //se tiver um path relativo complementa ele, senao tenta usar o full
  const pathAcessado = relativePath
    ? `${window.location.pathname}/${relativePath}`
    : pathname;

  if (!pathAcessado) {
    console.error("📕 pathAcessado vazio");
    return false;
  }

  //ReactGA.set({ page: pathAcessado });

  if (modificarNaBarraTitulo) {
    window.history.pushState({}, modificarNaBarraTitulo, pathAcessado);
  }

  //return ReactGA.pageview(pathAcessado);
};

//componente wrapper to analytics
// export default class GAListener extends Component {
//   static contextTypes = {
//     router: PropTypes.object,
//   };

//   static propTypes = {
//     children: PropTypes.object.isRequired,
//   };

//   componentDidMount() {
//     const { router } = this.context;

//     sendPageView(router.history.location);
//     router.history.listen(sendPageView);
//   }

//   render() {
//     return this.props.children;
//   }
// }
