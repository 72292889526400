import React, { useState } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import MaskedInput from "react-text-mask";
import { device } from "../../styles/devices";
import { withRouter } from "react-router-dom";
import axios from "axios";
import queryString from "query-string";
import { sendPageView } from "../../GAListener";

import { formularioControlesDefault, preparaLead } from "./contato.helpers";
import { animacaoBounce, animacaoGira } from "../../styles/efeitos";
import Icon from "../ui/Icon";
import { InputStyled, SelectStyled, InputRow } from "../ui/Inputs";
import { telefoneMask, celularMask } from "./masks";
import { pegaEstadosUf, buscaCidadesPorEstado } from "../../helpers/locais";

const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
`;

const InputsWrapper = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
`;

const IconeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  width: 18%;
  background-color: #52a028;
  transition: all 0.2s;
`;

const Submit = styled.button`
  background-color: #61bb31;
  color: #fff;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  font-size: 1.6rem;
  margin: 1.4rem 0.5rem 0.5rem;
  transition: all 0.2s;
  align-self: center;

  /* fazendo ele ficar full de um jeito nao muito bonito */
  width: 100%;
  margin-bottom: -1rem;
  border-radius: 0 0 5px 5px;

  /* posicionando o icone */
  display: flex;
  align-items: stretch;
  justify-content: center;
  overflow: hidden;

  span {
    flex: 1;
    padding: 1.8rem 2rem;
    text-transform: uppercase;
    font-size: 1.8rem;
  }
  &:hover {
    ${props =>
      !props.estaEnviando &&
      css`
        background-color: #fff;
        color: #61bb31;
        ${IconeWrapper} {
          background-color: #e4e4e4;
          svg {
            fill: #61bb31;
            animation: ${animacaoBounce} 0.6s ease-in-out 2;
          }
        }
      `}
  }
  ${props =>
    props.estaEnviando &&
    css`
      background: #ffa000;
      cursor: initial;
      pointer-events: none;
      color: ${props => props.theme.greyDarker};
      ${IconeWrapper} {
        background-color: #ec9422;
        svg {
          fill: ${props => props.theme.greyDarker};
          animation: ${animacaoGira} 1.3s ease-in-out infinite;
        }
      }
    `}
  /* &:focus {
    transform: translateY(0);
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
  } */

  @media ${device.laptop} {
    margin span {
      padding: 1.4rem 2rem;
    }
  }
  @media ${device.mobileM} {
    span {
      font-size: 1.6rem;
      padding: 1.6rem 1rem;
      font-weight: bold;
    }
  }
`;

const TextoErro = styled.p`
  text-align: center;
  color: ${props => props.theme.cores.um};
  margin-top: 0.5rem;
`;

const TextoSucessoWrapper = styled.div`
  padding: 2rem;
  text-align: center;
`;

const TituloSucesso = styled.h2`
  margin-top: 1rem;
  color: ${props => props.theme.cores.um};
  font-size: 2rem;
  font-weight: normal;
`;

const TextoSucesso = styled.p`
  margin-top: 1rem;
  color: ${props => props.theme.cores.quatro};
  font-size: 1.5rem;
  font-weight: normal;
`;

const LabelOculto = styled.label`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;

function Formulario(props) {
  //controles de envio e form
  const [controlesFormulario, setControlesFormulario] = useState({
    ...formularioControlesDefault.pronto,
  });

  //infos do lead
  const [lead, setLead] = useState({
    nome: "",
    email: "",
    telefone: "",
    cidade_id: "",
  });

  const estadosDisponiveis = pegaEstadosUf();
  const [estadoUf, setEstadoUf] = useState("");
  const [textoSelectCidade, setTextoSelectCidade] = useState("Cidade");
  const [cidadesDisponiveis, setCidadesDisponiveis] = useState([]);

  /**
   * altera os campos conforme o input do user
   * @param {object} event evento do listener
   */
  const onChangeLead = ({ currentTarget: { value, name } }) => {
    setLead({ ...lead, [name]: value });
  };

  /**
   * altera o estado selecionado e busca as cidades correspondentes
   * @param {object} event evento do listener
   */
  const onChangeEstado = ({ currentTarget: { value } }) => {
    setEstadoUf(value);

    //buscar cidades
    setTextoSelectCidade("Carregando...");
    buscaCidadesPorEstado(value).then(cidades => {
      if (cidades) {
        const principaisCidades = cidades
          .filter(({ prioridade }) => prioridade) //filtrando antes para melhorar o desempenho do sort
          .sort(({ prioridade: prA }, { prioridade: prB }) =>
            prA > prB ? 1 : -1,
          )
          .map(cidade => ({
            ...cidade,
            principal: true, //adicionando flag para modifiar a key no render
          }))
          .slice(0, 5); //pegando somente 5

        const cidadesDisponiveis = principaisCidades.length
          ? [
              ...principaisCidades,
              {
                id: "nope",
                nome: "--------",
              },
              ...cidades,
            ]
          : cidades;

        setCidadesDisponiveis(cidadesDisponiveis);
        setTextoSelectCidade("Cidade");
      }
    });
  };

  /**
   *
   * @param {object} e evento default do submit
   */
  const onFormSubmit = async e => {
    e.preventDefault();

    setControlesFormulario(formularioControlesDefault.enviando);

    //validacao simples
    if (!lead.nome || !lead.cidade_id || !lead.email || !lead.telefone) {
      setControlesFormulario({
        ...formularioControlesDefault.erro,
        textoAjuda:
          "Por favor, verique se todos os campos estão preenchidos e tente novamente",
      });

      return;
    }

    //vamos pegar os parametros da url
    const queryParams = queryString.parse(props.location.search);

    //vamos precisar pega o nome full da cidade e estado para o Mautic
    lead.estadoNome = estadosDisponiveis.find(
      estado => estado.id === estadoUf,
    ).nome;
    lead.cidadeNome = cidadesDisponiveis.find(
      cidade => `${cidade.id}` === `${lead.cidade_id}`,
    ).nome;

    const data = preparaLead({ ...lead, ...queryParams });

    try {
      const resposta = await axios.post(
        "/.netlify/functions/contato",
        //"https://hookb.in/eKG9rKRzBOUG8GR3Kppo",
        data,
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
          },
        },
      );
      console.log("resposta", resposta);

      if (resposta) {
        //mostrando mensagem de sucesso
        //insereTagConversao();

        setControlesFormulario(formularioControlesDefault.sucesso);

        //avisando o componente acima se existir
        if (props.setContatoDisparado) props.setContatoDisparado(true);

        //limpando o lead
        setLead({
          nome: "",
          email: "",
        });
      }

      //fazendo o redir para pageview
      sendPageView({
        pathname: "/sucesso",
        modificarNaBarraTitulo: "Sucesso",
      });
    } catch (error) {
      console.error(error);

      setControlesFormulario(formularioControlesDefault.erro);
    }
  };

  return (
    <FormStyled className={props.className} onSubmit={onFormSubmit}>
      {!controlesFormulario.foiEnviado && (
        <InputsWrapper>
          <LabelOculto htmlFor="nome">Nome completo</LabelOculto>
          <InputStyled
            type="text"
            tabIndex={0}
            placeholder="Nome completo"
            aria-label="Nome completo"
            name="nome"
            id="nome"
            onChange={onChangeLead}
            value={lead.nome}
          />
          <LabelOculto htmlFor="email">E-mail</LabelOculto>
          <InputStyled
            type="email"
            tabIndex={0}
            placeholder="E-mail"
            aria-label="E-mail"
            name="email"
            id="email"
            onChange={onChangeLead}
            value={lead.email}
          />
          <LabelOculto htmlFor="telefone">Telefone</LabelOculto>
          <InputStyled
            as={MaskedInput}
            mask={value => (value.length > 13 ? celularMask : telefoneMask)}
            type="tel"
            tabIndex={0}
            placeholder="Telefone"
            aria-label="Telefone"
            name="telefone"
            id="telefone"
            onChange={onChangeLead}
            value={lead.telefone}
            guide={false}
          />
          <InputRow>
            <LabelOculto htmlFor="estado_uf">UF</LabelOculto>
            <SelectStyled
              pequeno
              as="select"
              value={estadoUf}
              name="estado_uf"
              aria-label="UF"
              id="estado_uf"
              onChange={onChangeEstado}
            >
              <option value="">UF</option>
              {estadosDisponiveis &&
                estadosDisponiveis.map(({ id: estadoUf }) => (
                  <option key={estadoUf} value={estadoUf}>
                    {estadoUf}
                  </option>
                ))}
            </SelectStyled>
            <LabelOculto htmlFor="cidade_id">Cidade</LabelOculto>
            <SelectStyled
              as="select"
              value={lead.cidade_id}
              name="cidade_id"
              id="cidade_id"
              aria-label="Cidade"
              onChange={onChangeLead}
              disabled={!cidadesDisponiveis.length}
            >
              <option value="" disabled>
                {textoSelectCidade}
              </option>
              {cidadesDisponiveis &&
                cidadesDisponiveis.map(
                  ({ id: cidadeId, nome: cidadeNome, principal }) => (
                    <option
                      key={principal ? `p${cidadeId}` : cidadeId}
                      value={cidadeId}
                      disabled={cidadeId === "nope"}
                    >
                      {cidadeNome}
                    </option>
                  ),
                )}
            </SelectStyled>
          </InputRow>
          {controlesFormulario.temErro && (
            <TextoErro>{controlesFormulario.textoAjuda}</TextoErro>
          )}
        </InputsWrapper>
      )}
      {!controlesFormulario.foiEnviado && (
        <Submit type="submit" estaEnviando={controlesFormulario.estaEnviando}>
          <span>{controlesFormulario.textoBotao}</span>
          <IconeWrapper>
            <Icon
              icon={controlesFormulario.estaEnviando ? "spinner" : "download"}
              cor="#ffffff"
              tamanho="2rem"
            />
          </IconeWrapper>
        </Submit>
      )}
      {controlesFormulario.foiEnviado && (
        <TextoSucessoWrapper>
          <TituloSucesso>Parabéns por preencher o formulário!</TituloSucesso>
          <TextoSucesso>
            Agora, é hora de fazer seu negócio lotar toda semana, pois o e-book
            está chegando a seu e-mail em instantes. Leia e aproveite esse
            momento de aprendizado.
          </TextoSucesso>
        </TextoSucessoWrapper>
      )}
    </FormStyled>
  );
}

Formulario.propTypes = {
  setContatoDisparado: PropTypes.func,
};

export default withRouter(Formulario);
