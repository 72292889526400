import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components";
import { device } from "../../styles/devices";

import { ContainerParaFormFixo } from "../../modules/ui/Containers";
import { ButtonPrimary } from "../../modules/ui/Buttons";
import { TituloPrimario } from "../../modules/ui/Tipografia";

const SobreStyled = styled.section`
  background-color: ${props => props.theme.cores.quatro};
  padding: 4rem 0;
  color: white;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 35%;
    top: 0;
    right: 0;
    background-color: #0d304c;

    @media ${device.laptop} {
      display: none;
    }
  }

  @media ${device.laptop} {
    text-align: center;
  }
`;

const ButtonCall = styled(ButtonPrimary)`
  text-transform: uppercase;
  margin-top: 2rem;
`;

const TituloConfira = styled(TituloPrimario)`
  margin-top: 2rem;
  @media ${device.laptop} {
    margin: 2rem auto 0;
  }
`;

const Ebook = styled.img`
  margin-top: -24rem;
  max-width: 50rem;
`;

const MuitoMais = styled.span`
  display: block;
  margin-top: 2rem;
`;

const ListaTopicos = styled.ul`
  margin-top: 2rem;
  list-style: disc;
`;

const Sobre = ({ darFocoFormFixo }) => (
  <SobreStyled>
    <ContainerParaFormFixo>
      <picture>
        <source srcSet="/img/ebook-300.png" media="(max-width: 400px)" />
        <source srcSet="/img/ebook-400.png" media="(max-width: 500px)" />
        <Ebook
          src="/img/ebook-600.png"
          alt="eBook Voucher Fácil: 7 Dicas para seu Restaurante Lotar a Semana Toda"
        />
      </picture>
      <p>
        Tenha a oportunidade de fazer seu negócio lotar, refletindo sobre “o
        quê” e “como” fazer. Neste ebook do Voucher Fácil, você terá dicas
        valiosas e verá histórias de quem conseguiu aumentar o público-alvo,
        seja em local físico ou serviço on-line.
      </p>
      <TituloConfira as="h2">
        Confira alguns tópicos apresentados:
      </TituloConfira>
      <ListaTopicos>
        <li>Criar um ambiente que se encaixe com sua proposta</li>
        <li>Aproveitar as datas sazonais para chamar atenção</li>
        <li>Como aplicar uma estratégia de marketing e redes sociais</li>
        <li>Exemplos de restaurantes e profissionais da gastronomia</li>
      </ListaTopicos>
      <MuitoMais>E muito mais!</MuitoMais>
      {!!darFocoFormFixo && (
        <ButtonCall onClick={darFocoFormFixo}>Download grátis</ButtonCall>
      )}
    </ContainerParaFormFixo>
  </SobreStyled>
);

Sobre.propTypes = {
  darFocoFormFixo: PropTypes.func
};

Sobre.defaultProps = {
  darFocoFormFixo: null
};

export default Sobre;
