import React from "react";
import styled from "styled-components";
import { device } from "../styles/devices";
import { FlexContainer } from "../modules/ui/Containers";
import Logo from "../modules/ui/Logos";

const FooterStyled = styled.footer`
  padding: 3rem 0;
  @media ${device.laptop} {
    padding: 4rem 0 3rem;
  }
`;

const FooterContainer = styled(FlexContainer)`
  justify-content: space-around;
  align-items: center;
`;

const TextoVoucher = styled.p`
  margin-left: 3rem;
  color: ${props => props.theme.greyDarker};

  a {
    color: ${props => props.theme.cores.quatro};
    font-weight: 600;
  }
`;

const Footer = () => {
  return (
    <FooterStyled>
      <FooterContainer>
        <Logo colorido tamanho="10rem" />
        <TextoVoucher>
          O Voucher Fácil é uma plataforma gratuita que facilita o seu acesso às
          melhores ofertas, para você economizar em todo o tipo de serviços.
          Para usar, é só acessar a oferta, preencher os dados, e validar o seu
          Voucher Fácil diretamente no estabelecimento, no dia e horário
          marcado! Quer saber mais? Conheça nosso site:{" "}
          <a
            href="https://www.voucherfacil.com.br?origem=Ebook"
            title="Conheça o Voucher Fácil"
            rel="external noopener noreferrer"
            target="_blank"
          >
            www.voucherfacil.com.br
          </a>
        </TextoVoucher>
      </FooterContainer>
    </FooterStyled>
  );
};

export default Footer;
