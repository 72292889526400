/**
 * corrige o problema de as vezes usar os parametros de ads com ou sem o prefixo 'utm_'
 * @param {object} lead os dados completos do lead ou um dataset somente com os a serem corrigidos
 */
export const corrigeAdsParams = lead => ({
  ...lead,
  source: lead.source || lead.utm_source,
  medium: lead.medium || lead.utm_medium,
  term: lead.term || lead.utm_term,
  campaign: lead.campaign || lead.utm_campaign,
  content: lead.content || lead.utm_content,
});

/**
 * faz as transformacoes necessarias no corpo do lead antes do disparo
 * @param {object} lead os dados completos do lead
 */
export const preparaLead = lead => {
  let leadPreparado = corrigeAdsParams(lead);

  //adicionando identificacao da plataforma
  leadPreparado["ferramenta_codigo"] = "ebook";

  //formatando conforme a funcao espera para enviar para o mautic
  const nomes = leadPreparado.nome.split(" ");

  const mauticLead = {
    contact: {
      ...leadPreparado,
      firstname:
        nomes.length > 1
          ? nomes.slice(0, nomes.length - 1).join(" ")
          : nomes[0],
      lastname: nomes.length > 1 ? nomes[nomes.length - 1] : "",
      ipAddress: "192.168.0.1",
      url: "conteudos.voucherfacil.com.br/ebook",
      country: "Brazil",
      phone: leadPreparado.telefone,
      city: leadPreparado.cidadeNome,
      //no estado precisamos limpar caracteres especiais
      state: leadPreparado.estadoNome
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, ""),
    },
    utmtags: {
      utm_campaign: leadPreparado.campaign || "",
      utm_source: leadPreparado.source || "",
      utm_medium: leadPreparado.medium || "",
      utm_content: leadPreparado.content || "",
      utm_term: leadPreparado.term || "",
    },
  };

  return mauticLead;
};

export const formularioControlesDefault = {
  pronto: {
    estaEnviando: false,
    temErro: false,
    foiEnviado: false,
    textoBotao: "Fazer download do eBook",
    textoAjuda: "",
  },
  enviando: {
    estaEnviando: true,
    temErro: false,
    foiEnviado: false,
    textoBotao: "Enviando...",
    textoAjuda: "",
  },
  erro: {
    estaEnviando: false,
    temErro: true,
    foiEnviado: false,
    textoBotao: "Fazer download do eBook",
    textoAjuda: "Ops! Algo deu errado. Por favor, tente novamente.",
  },
  sucesso: {
    estaEnviando: false,
    temErro: false,
    foiEnviado: true,
    textoBotao: "Fazer download do eBook",
    textoAjuda: "Feito!",
  },
};
